!function (library) {
    // Calls the second IIFE and locally passes in the global jQuery, window, and document objects
    library(window, document, window.jQuery);
}

// Locally scoped parameters 
(function (window, document, $) {

    var $body  = $('body')  // Variables that are used to store/cache jQuery objects should have a name prefixed with a $

    var locale = $body.data('locale');

    // The DOM is ready!
    $(initPage);
    function initPage(){

        $('#volunteer').length && volunteerHandler();
        $('#manager').length && managerHandler();
    }


    // The rest of your code goes here! ---------------------------------------


    /*
    |--------------------------------------------------------------------------
    | EVENTS
    |--------------------------------------------------------------------------
    */

    $('.back-to-top').on('click', backToTop);

    function backToTop(e){
        e.preventDefault();
        
        var offset = $body.offset();
        $('html,body').animate({
            scrollTop: offset.top,
            scrollLeft: offset.left
        });
    }

    // Toggle search bar
    $('a[href="#toggle-search"], .navbar-with-search .search-bar .input-group-btn > .btn[type="reset"]').on('click', toggleSearchBar);

    function toggleSearchBar(e) {
        e.preventDefault();
        
        $('.navbar-with-search .search-bar .input-group > input').val('');
        $('.navbar-with-search .search-bar').toggleClass('open');
        $('a[href="#toggle-search"]').closest('li').toggleClass('active');

        if ($('.navbar-with-search .search-bar').hasClass('open')) {
            /* I think .focus dosen't like css animations, set timeout to make sure input gets focus */
            setTimeout(function() { 
                $('.navbar-with-search .search-bar .form-control').focus();
            }, 100);
        }           

    }


    // Hide search bar when clicking escape    
    $(document).on('keyup', escapeKey);

    function escapeKey(e) {
        if (event.which == 27 && $('.navbar-with-search .search-bar').hasClass('open')) {
            $('a[href="#toggle-search"]').trigger('click');
        }
    }


    // Hide search bar when showing dropdown menu
    $('.dropdown-toggle').on('mouseover', hoverDropdown);

    function hoverDropdown() {
        if ($('.navbar-with-search .search-bar').hasClass('open')) {
            $('a[href="#toggle-search"]').trigger('click');
        }
    }



    /*
    |--------------------------------------------------------------------------
    | ASSOCIATE FORMS
    |--------------------------------------------------------------------------
    */

    $('#volunteer').find('select').on('change', volunteerHandler);

    function volunteerHandler()
    {
        if($('#volunteer').find('select').val()=='yes'){
            $('#volunteer_descr').removeClass('hide');
        } else {
            $('#volunteer_descr').addClass('hide');
        }
    }

    $('#manager').find('select').on('change', managerHandler);

    function managerHandler()
    {
        if($('#manager').find('select').val()=='yes'){
            $('#manager_descr').removeClass('hide');
        } else {
            $('#manager_descr').addClass('hide');
        }
    }

    $('.associados input[type="checkbox"]').on('change', usersHandler);

    function usersHandler()
    {
        if (this.checked) {
            $(this).siblings('.associate-users').removeClass('hidden');
        } else {
            $(this).siblings('.associate-users').addClass('hidden');
        }
    }

    /*
    |--------------------------------------------------------------------------
    | NEWSLETTER
    |--------------------------------------------------------------------------
    */
    $('#modal-mailchimp form').on('submit', function(){
        $('#mce-success-response').show();
    });

});
